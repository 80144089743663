import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const AuthModal = ({ show = false, handleClose, children }) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="auth-modal">
      <Modal.Header
        closeButton
        style={{
          border: "none",
        }}
        className="auth-modal-header"
      >
        <img src="/images/logo.png" alt="" width={200} className="mx-auto" />
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default AuthModal;
