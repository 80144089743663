import React, { useEffect, useState } from "react";
import { Modal, Button, Form, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setLocation,
  setModalVisible,
  setActiveType,
} from "../actions/locationSlice"; // Import actions
import api from "../services/api";
import { clearCart } from "../reducers/cartReducer";

const LocationModal = () => {
  const dispatch = useDispatch();
  const { selectedLocation, activeType, modalVisible } = useSelector(
    (state) => state.location
  );

  // State for storing restaurant options
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    api.fetchRestaurants(setRestaurants); // Call the function to fetch restaurants when the component mounts
  }, []);

  const handleSelect = (restaurant) => {
    dispatch(clearCart());
    dispatch(setLocation(JSON.parse(restaurant)));
  };
  const handleClose = () => {
    if (selectedLocation) {
      dispatch(setModalVisible(false)); // Close modal when a location is selected
    }
  };

  return (
    <Modal show={modalVisible} centered className="location-modal">
      <Modal.Body className="p-4">
        {/* Logo in center */}
        <div className="text-center mb-3">
          <img src="/images/logo.png" alt="logo" className="modal-logo" />
        </div>

        {/* Title */}
        <h4 className="text-center">Please Select Location</h4>

        {/* Toggle Buttons for Pickup */}
        <ButtonGroup className="mb-3 togglegroup">
          <Button
            variant={activeType === "pickup" ? "primary" : "outline-primary"}
            className="flex-fill"
            onClick={() => dispatch(setActiveType("pickup"))}
          >
            Pickup
          </Button>
          {/* Add more button options if needed */}
        </ButtonGroup>

        {/* Select Restaurant */}
        <Form.Group className="mb-3">
          <Form.Control
            as="select"
            value={JSON.stringify(selectedLocation)}
            onChange={(e) => handleSelect(e.target.value)}
            className="custom-select"
          >
            {!selectedLocation && (
              <option value="">Please Select a Restaurant</option>
            )}

            {restaurants.map((restaurant) => (
              <option
                key={restaurant.restaurant_external_id}
                value={JSON.stringify(restaurant)}
              >
                {restaurant.restaurant_name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Full-width Select Button */}
        <Button onClick={handleClose} className="w-100 selectbtn">
          Select
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LocationModal;
