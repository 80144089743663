import Offcanvas from "react-bootstrap/Offcanvas";
import { IoClose } from "react-icons/io5";

const Drawer = ({ show, setShow, children, style, headerClassName }) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        scroll
        placement="end"
        style={{ ...style }}
      >
        <Offcanvas.Header
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button
            onClick={handleClose}
            className="drawer-close-btn"
            style={{
              color: "white",
            }}
          >
            <IoClose />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Drawer;
