import { createSlice } from "@reduxjs/toolkit";
import jwtService from "../../services/local/JwtService";

const session = jwtService.useSession();

const initialState = {
  isLoggedIn: !!session?.token ?? false,
  user: session?.user ?? null,
  token: session?.token ?? "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth(state, action) {
      const { user, token } = action.payload;

      state.isLoggedIn = true;
      state.user = user;
      state.token = token;

      jwtService.setAuthSession({
        user,
        token,
      });
    },
    updateUser(state, action) {
      const oldUserDetails = state.user;

      const newUserDetails = action.payload.user;

      const updatedUser = {
        ...oldUserDetails,
        ...newUserDetails,
      };

      state.user = updatedUser;

      jwtService.updateAuthSession({
        user: updatedUser,
      });
    },
    updateToken(state, action) {
      const { token } = action.payload;

      state.token = token;

      jwtService.updateAuthSession({
        token,
      });
    },
    logout(state, action) {
      state.isLoggedIn = false;
      state.user = null;
      state.token = "";

      jwtService.clearSession();
    },
  },
});
