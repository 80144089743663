import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import customerService from "../../../../services/customer-service";
import PageSpinner from "../../../../components/PageSpinner";
import { format } from "date-fns";

const Item = ({ name, quantity, sub_total, modifier, tax, image, guid }) => {
  return (
    <div className="item">
      <div>
        <p className="label">{name}</p>
        <p className="label-detail">x {quantity}</p>
      </div>

      <div>
        <p className="label">${sub_total ?? 0}</p>
      </div>
    </div>
  );
};

const ItemsGrid = ({ items }) => {
  return (
    <div className="items">
      {items?.map(
        ({ name, quantity, sub_total, modifier, tax, image, guid }, i) => (
          <Item
            guid={guid}
            name={name}
            quantity={quantity}
            sub_total={sub_total}
            image={image}
            modifier={modifier}
            tax={tax}
          />
        )
      )}
    </div>
  );
};

const OrderDetail = () => {
  const { id } = useParams();

  const { data, error, isFetching } = useQuery({
    queryKey: ["order-details", id],
    queryFn: () => customerService.getOrderDetail(id),
    refetchOnWindowFocus: false,
  });

  const [amountDetails, setAmountDetails] = useState({
    tax: 0,
    subTotal: 0,
    grandTotal: 0,
  });

  useEffect(() => {
    if (data?.order_detail?.length > 0) {
      const calculateTotals = (items) => {
        const totals = items.reduce(
          (acc, item) => {
            acc.totalSubTotal += item.sub_total || 0;
            acc.totalTax += item.tax || 0;
            return acc;
          },
          { totalSubTotal: 0, totalTax: 0 }
        );

        const grandTotal = (totals.totalSubTotal + totals.totalTax)?.toFixed(2);

        setAmountDetails({
          tax: totals.totalTax,
          subTotal: totals.totalSubTotal,
          grandTotal,
        });
      };

      calculateTotals(data?.order_detail);
    }

    return () => {};
  }, [data]);

  if (isFetching) {
    return <PageSpinner minHeight="70vh" />;
  }

  if (error) {
    return <></>;
  }

  return (
    <div
      className="container my-4"
      style={{
        minHeight: "70vh",
      }}
    >
      <div className="order-detail-container">
        <div className="box gap-4">
          <div className="detail-card">
            <div className="detail-box">
              <p className="label">Order Number</p>
              <p className="order-number">#{data?.displayNumber}</p>
            </div>
            <div className="detail-box">
              <p className="label">Date</p>
              <p className="label-detail">
                {data?.order_date
                  ? format(data?.order_date, "EEE, d MMM yyyy hh:mm a")
                  : ""}
              </p>
            </div>
            {/* <div className="detail-box">
              <p className="label">Payment Method</p>
              <p className="label-detail">Card</p>
            </div> */}
          </div>
          <div className="detail-card">
            <div className="subtotal-detail">
              <div className="">
                <p className="label m-0">Sub Total</p>
                <p className="label-detail m-0">${amountDetails.subTotal}</p>
              </div>
              <div className="">
                <p className="label m-0">Tax</p>
                <p className="label-detail m-0">${amountDetails.tax}</p>
              </div>
              <div className="">
                <p className="label m-0">Total</p>
                <p className="label m-0">${amountDetails.grandTotal}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="box ">
          <div className="detail-card">
            <h4>ITEMS</h4>
            <ItemsGrid items={data?.order_detail} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
