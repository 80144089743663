export const HOME_ROUTE = "/";

// order
export const ORDER_DETAIL_ROUTE = "/order-detail";
export const ORDER_HISTORY_ROUTE = "/order-history";
export const THANK_YOU_ROUTE = "/thank-you";

// site-specific
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const FAQS_ROUTE = "/faqs";
