import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import BtnLink from "../components/BtnLink";

import { HOME_ROUTE } from "../routes";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import customerService from "../services/customer-service";
import Skeleton from "react-loading-skeleton";
import { format } from "date-fns";

import "react-loading-skeleton/dist/skeleton.css";
import LogoHeader from "../components/LogoHeader";

const OrderDetails = () => {
  const { id } = useParams();

  const { data, isLoading, error } = useQuery({
    queryKey: ["order-details", id],
    queryFn: () => customerService.getOrderDetail(id),
    refetchOnWindowFocus: false,
  });

  const [amountDetails, setAmountDetails] = useState({
    tax: 0,
    subTotal: 0,
    grandTotal: 0,
  });

  useEffect(() => {
    if (data?.order_detail?.length > 0) {
      const calculateTotals = (items) => {
        const totals = items.reduce(
          (acc, item) => {
            acc.totalSubTotal += item.sub_total || 0;
            acc.totalTax += item.tax || 0;
            return acc;
          },
          { totalSubTotal: 0, totalTax: 0 }
        );

        const grandTotal = totals.totalSubTotal + totals.totalTax;

        setAmountDetails({
          tax: totals.totalTax,
          subTotal: totals.totalSubTotal,
          grandTotal,
        });
      };

      calculateTotals(data?.order_detail);
    }

    return () => {};
  }, [data]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="order-details">
      <p>Order #{data.displayNumber}</p>

      <div className="detail">
        <p>Total</p>
        <p>${amountDetails?.grandTotal?.toFixed(2)}</p>
      </div>

      <div className="detail">
        <p>Pickup Time</p>
        <p>{data?.pickup_time ? format(data.pickup_time, "hh:mm a") : ""}</p>
      </div>
    </div>
  );
};

const ThankYouPage = () => {
  return (
    <>
      <LogoHeader />
      <div
        className="container"
        style={{
          minHeight: "70vh",
        }}
      >
        <div className="thank-you-page">
          <img src="/images/completed.svg" alt="" />
          <div className="my-3 text-center">
            <p className="mb-2">Your order has been placed</p>
            <h2>THANK YOU</h2>
          </div>

          <OrderDetails />

          <BtnLink
            href={HOME_ROUTE}
            minWidth="250px"
            style={{
              margin: "20px 0px",
            }}
          >
            Back to Home
          </BtnLink>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ThankYouPage;
