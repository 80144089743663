import { configureStore } from "@reduxjs/toolkit";
import locationReducer from "../actions/locationSlice"; // Import location reducer
import cartReducer from "../reducers/cartReducer"; // Import cart reducer
import apiReducer from "../reducers/apiSlice"; // Import your new API slice
import authReducer from "../reducers/auth";

export const store = configureStore({
  reducer: {
    location: locationReducer,
    cart: cartReducer, // Add cart reducer
    api: apiReducer, // Add the API reducer here
    auth: authReducer,
  },
});
