export const frequentlyAskedQ = [
  {
    heading: "Ordering & Pickup Services",
    faqs: [
      {
        question: "How can I place an order online?",
        answer: {
          text: "You can easily place an order through our website, Grabeasy. Simply choose your favorite items, add them to your cart, and follow the checkout process. You’ll receive a confirmation once your order is placed.",
        },
      },
      {
        question: "What pickup options do you offer?",
        answer: {
          text: "We offer two convenient pickup options:",
          points: [
            "Counter Pickup: Collect your order directly from the counter inside our restaurant.",
            "Drive-Through Pickup: No need to leave your car! Just drive through, and we’ll bring your order to you.",
          ],
        },
      },
      {
        question: "How will I know when my order is ready for pickup?",
        answer: {
          text: "We will send you a notification via SMS or email once your order is ready for pickup. You can also track your order status in realtime on our website.",
        },
      },
      {
        question: "Can I modify my order after placing it?",
        answer: {
          text: "Please contact us as soon as possible if you need to make changes. While we will do our best to accommodate your request, we may not be able to modify orders that are already being prepared.",
        },
      },
      {
        question: "What should I do when I arrive for Drive-Through Pickup?",
        answer: {
          text: "When you arrive at our restaurant, simply follow the signs for the Drive-Through Pickup. Provide your order number, and our team will bring your order to your car.",
        },
      },
    ],
  },
  {
    heading: "Gift Cards",
    faqs: [
      {
        question: "Do you offer gift cards?",
        answer: {
          text: "Yes! We offer both physical and digital gift cards that can be used at our restaurant or when ordering online through Grabeasy.",
        },
      },
      {
        question: "How can I purchase and receive a digital gift card?",
        answer: {
          text: "You can purchase digital gift cards through our Grabeasy app. Once purchased, the gift card will be sent and stored directly in the 'Gift Card' section of the app for easy access.",
        },
      },
      {
        question: "Can I use a gift card for online orders?",
        answer: {
          text: "Absolutely! When placing an order online or through the Grabeasy app, just select the gift card from the 'Gift Card' section at checkout to apply the balance to your order.",
        },
      },
      {
        question: "Do gift cards expire?",
        answer: {
          text: "No, our gift cards do not have an expiration date. You can use them whenever you like!",
        },
      },
    ],
  },
  {
    heading: "Loyalty Program",
    faqs: [
      {
        question: "Do you have a loyalty program?",
        answer: {
          text: "Yes! We offer a loyalty program with two exciting ways to earn rewards:",
          points: [
            "Burgers Loyalty: Buy 9 burgers, and your 10th burger is free!",
            "Points Program: Complete tasks to earn loyalty points, and once you reach 100 points, they are automatically applied at checkout.",
          ],
        },
      },
      {
        question: "How do I join the loyalty program?",
        answer: {
          text: "You can join our loyalty program by signing up on our website or through the Grabeasy mobile app. It’s free, and you’ll start earning points right away!",
        },
      },
      {
        question: "How do I earn points?",
        answer: {
          text: "You can earn points by completing specific tasks, such as ordering online, referring a friend, or participating in special promotions. You’ll also earn a free burger after purchasing 9 burgers.",
        },
      },
      {
        question: "How do I redeem my points?",
        answer: {
          text: "Once you've earned 100 loyalty points, they will be automatically applied at checkout when you place your next order.",
        },
      },
      {
        question: "Can I check my loyalty points balance?",
        answer: {
          text: "Yes! You can check your points balance by logging into your account on our website or the Grabeasy app.",
        },
      },
    ],
  },
];
