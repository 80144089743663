import React from "react";
import { Link } from "react-router-dom";
import { HOME_ROUTE } from "../routes";
import BackBtn from "./BackBtn";

const LogoHeader = ({ showBackBtn = false }) => {
  return (
    <header className="container-fluid">
      {showBackBtn && (
        <div className="container">
          <BackBtn />
        </div>
      )}
      <div className="row align-items-center">
        <div className="col-lg-12 col-12 logodiv text-center">
          <Link to={HOME_ROUTE}>
            <img src="/images/logo.png" alt="grab easy logo" className="logo" />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default LogoHeader;
