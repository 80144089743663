import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Footer from "../../../components/Footer";
import { useSelector } from "react-redux";
import api from "../../../services/api";
import PaymentForm from "./PaymentForm";
import PersonalInfo from "./PersonalInfo";
import useCheckout from "../hooks/useCheckout";
import Button from "../../../components/Button";

const Checkout = () => {
  const {
    formGroup,
    authSession,
    productsTotal,
    pickupOptions,
    selectPickupOption,
    voucher,
    applyVoucher,
    handleOrder,
    isLoading,
  } = useCheckout();

  return (
    <>
      <header className="container-fluid">
        <div className="row align-items-center">
          {/* Center Logo with link */}
          <div className="col-lg-12 col-12 logodiv text-center">
            <a href="/">
              <img
                src="/images/logo.png"
                alt="grab easy logo"
                className="logo"
              />
            </a>
          </div>
        </div>
      </header>

      <Container className="checkout">
        <Row>
          <Col md={7}>
            <Form>
              <PersonalInfo authSession={authSession} />
              <Form.Group className="mt-4" controlId="formPickUpOptions">
                <Form.Label>Pick-up Options</Form.Label>
                {pickupOptions?.map((item) => (
                  <Form.Check
                    key={item.guid}
                    type="radio"
                    className="checkout-radio"
                    name="pickupOption"
                    label={item.name}
                    value={item.guid}
                    id={item.guid}
                    onChange={selectPickupOption}
                  />
                ))}
              </Form.Group>

              <PaymentForm />

              {/* <Form.Group controlId="formMobileNumber" className='mt-4'>
                <div className="d-lg-flex justify-content-between">
                  <div className="halfwidth" style={{ marginRight: '20px' }}>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="text" placeholder="Mobile Number" />
                  </div>
                  <div className="halfwidth">
                    <Form.Label>Alternate Mobile Number</Form.Label>
                    <Form.Control type="text" placeholder="Alternate Mobile Number" />
                  </div>
                </div>
              </Form.Group> */}

              {/* <Form.Group controlId="formDeliveryAddress" className='mt-4'>
                <Form.Label>Delivery Address</Form.Label>
                <Form.Control as="textarea" rows={1} placeholder="Delivery Address" />
              </Form.Group> */}

              {/* <Form.Group controlId="formNearestLandmarkEmail" className='mt-4'>
                <div className="d-lg-flex justify-content-between">
                  <div className="halfwidth" style={{ marginRight: '20px' }}>
                    <Form.Label>Nearest Landmark</Form.Label>
                    <Form.Control type="text" placeholder="Nearest Landmark" />
                  </div>
                  <div className="halfwidth">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Email Address" />
                  </div>
                </div>
              </Form.Group> */}

              {/* <Form.Group controlId="formDeliveryInstruction" className='mt-4'>
                <Form.Label>Delivery Instruction</Form.Label>
                <Form.Control as="textarea" rows={1} placeholder="Delivery Instruction" />
              </Form.Group> */}
            </Form>
          </Col>

          {/* Column 5: Product Details */}
          <Col md={5}>
            <div className="product-details">
              {formGroup.products.length > 0 ? (
                formGroup.products.map((item) => (
                  <div key={item?.menu_item_guid} className="product-item">
                    <span>1 x {item?.menu_item_name}</span>
                    <span className="price">
                      ${item?.totalPrice.toFixed(2)}
                    </span>
                  </div>
                ))
              ) : (
                <p>Your cart is empty.</p>
              )}
              {formGroup.products.length > 0 && (
                <>
                  <hr />
                  <div className="total-amount mb-4">
                    <div className="d-flex justify-content-between">
                      <span>Sub Total</span>
                      <span>${productsTotal}</span>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <span>Tax</span>
                      <span>
                        ${(formGroup.totalAmount - productsTotal).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <span className="fw-bold">Grand Total</span>
                      <span className="fw-bold">${formGroup.totalAmount}</span>
                    </div>
                  </div>

                  {/* <div className="d-flex align-items-center mt-4 mb-4 coupondiv">
                    <Form.Control
                      type="text"
                      placeholder="Enter voucher / Promo code"
                      value={voucher}
                      onChange={applyVoucher}
                    />
                    <Button>Apply</Button>
                  </div> */}

                  {/* <Button
                    className="mt-3 w-100 placeorderbtn"
                    disabled={
                      formGroup.products.length === 0 ||
                      !!formGroup.pickup_drivethru
                    }
                  >
                    Place Order
                  </Button> */}

                  <Button
                    minWidth="100%"
                    disabled={
                      formGroup.products.length === 0 ||
                      !formGroup.pickup_drivethru ||
                      isLoading
                    }
                    handleClick={handleOrder}
                  >
                    {isLoading ? <Spinner size="sm" /> : "Place Order"}
                  </Button>
                </>
              )}

              <div className="continue-add-items mt-3">
                <Link to="/">
                  <FaArrowLeft className="me-2" style={{ color: "#FF8A00" }} />{" "}
                  {/* Left arrow icon */}
                  <span>Continue to add more items</span>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Checkout;
