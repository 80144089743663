import React from "react";
import LogoHeader from "../../components/LogoHeader";
import Footer from "../../components/Footer";
import Faqs from "../../components/Faqs";

const FaqsPage = () => {
  return (
    <>
      <LogoHeader showBackBtn />
      <Faqs />
      <Footer />
    </>
  );
};

export default FaqsPage;
