import React from "react";
import { frequentlyAskedQ } from "../constants";

const Faqs = () => {
  return (
    <div className="container my-4">
      <h1 className="page-heading">Frequently Asked Questions</h1>

      <div className="faqs">
        {frequentlyAskedQ.map((faqItem, i) => (
          <div className="faqs-container" key={i}>
            <h2>{faqItem.heading}</h2>
            <div className="faqs-box">
              {faqItem?.faqs?.map((faq, faqI) => (
                <div className="faq-content" key={`${i}${faqI}`}>
                  <div className="faq-question">
                    <input
                      id={`${i}${faqI}`}
                      type="checkbox"
                      className="panel"
                    />
                    <div className="plus">+</div>
                    <label htmlFor={`${i}${faqI}`} className="panel-title">
                      {faq.question}
                    </label>
                    <div className="panel-content">
                      <p>{faq.answer.text}</p>
                      {faq.answer.points && (
                        <ul>
                          {faq.answer.points.map((point, subIndex) => (
                            <li key={`${i}${faqI}${subIndex}`}>{point}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
