import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import AppConfig from "../config";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-center footer">
      {/* Social Media Icons */}
      <div className="social-icons mt-2">
        <a
          href={AppConfig.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
        >
          <FaLinkedin size={24} />
        </a>
        {/* <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2"
        >
          <FaInstagram size={24} />
        </a> */}
      </div>
      <p>
        © {currentYear} GrabEasy. All Rights Reserved. Powered by{" "}
        <a
          href={AppConfig.poweredBy}
          target="_blank"
          style={{
            fontWeight: 500,
            color: "#e57300",
          }}
        >
          Enorness
        </a>
      </p>
    </footer>
  );
};

export default Footer;
