import axios from "axios";
import AppConfig from "../config";
import Endpoints from "./Endpoints";
import BaseService from "./base-service";
import { store } from "../store/store";
import jwtService from "./local/JwtService";

class OrderService extends BaseService {
  async checkOrderPrices(payload) {
    try {
      const url = `${this.baseUrl}${Endpoints.tax}`;
      const res = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtService.getToken()}`,
          "Toast-Restaurant-ID":
            store.getState().location?.selectedLocation?.restaurant_external_id,
        },
      });
      return this.handleResponse(res);
    } catch (err) {
      return this.handleError(err);
    }
  }

  async getPickUpOptions() {
    return axios
      .get(`${this.baseUrl}${Endpoints.pickupOptions}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Toast-Restaurant-ID":
            store.getState().location?.selectedLocation?.restaurant_external_id,
        },
      })
      .then((response) => {
        console.log("response.data.data) ==>", response.data.data);
        return response.data.data;
      })
      .catch((err) => {
        return [];
      });
  }

  async createOrder(payload) {
    try {
      const url = `${this.baseUrl}${Endpoints.placeOrder}`;
      const res = await axios.post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtService.getToken()}`,
          "Toast-Restaurant-ID":
            store.getState().location?.selectedLocation?.restaurant_external_id,
        },
      });
      return this.handleResponse(res);
    } catch (err) {
      return this.handleError(err);
    }
  }
}

const orderService = new OrderService();

export default orderService;
