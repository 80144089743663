import React from "react";
import { Button } from "react-bootstrap";
import { FaPhone, FaMapMarkerAlt, FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setModalVisible } from "../actions/locationSlice"; // Import action
import AccountMenu from "./AccountMenu";

const Header = ({ handleViewCartClick }) => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state) => state.location.selectedLocation
  );
  console.log("selectedLocation ==>", selectedLocation);

  const cartItems = useSelector((state) => state.cart.items);

  return (
    <header className="container-fluid">
      <div className="row align-items-center">
        {/* Left Side */}
        <div className="col-lg-2 col-6 d-flex justify-content-end">
          <Button
            className="header-button d-flex align-items-center mr-3"
            onClick={() => dispatch(setModalVisible(true))} // Trigger modal visibility
          >
            <FaMapMarkerAlt className="icon" />
            <div className="ml-2">
              <span className="button-text">Change Location</span>
              <div className="location-text">
                {selectedLocation?.restaurant_name || "Select Location"}
              </div>
            </div>
          </Button>
        </div>

        {/* Phone button with link */}

        <div className="col-lg-2 col-6 d-flex justify-content-start">
          <a
            href={`tel:${selectedLocation?.restaurant_phone}`}
            className="header-button d-flex align-items-center text-decoration-none"
          >
            <FaPhone className="icon" style={{ rotate: "105deg" }} />
            <span className="ml-2 button-text">
              {selectedLocation?.restaurant_phone}
            </span>
          </a>
        </div>

        {/* Center Logo */}
        <div className="col-lg-4 col-6 logodiv text-center">
          <a href="/">
            <img src="/images/logo.png" alt="grab easy logo" className="logo" />
          </a>
        </div>

        {/* Right Side */}
        <div className="col-lg-4 col-6 d-flex justify-content-end align-items-center gap-4">
          <div className="cart-container d-flex align-items-center">
            <FaShoppingCart
              className="cart-icon"
              style={{ cursor: "pointer" }}
              onClick={() => handleViewCartClick()}
            />
            {cartItems.length > 0 && (
              <span className="cart-value">{cartItems.length}</span>
            )}
          </div>
          <AccountMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
