import axios from "axios";
import AppConfig from "../config";
import Endpoints from "./Endpoints";
import BaseService from "./base-service";

class AuthService extends BaseService {
  async authenticate(payload) {
    try {
      const url = `${this.baseUrl}${Endpoints.login}`;
      const res = await axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return this.handleResponse(res);
    } catch (err) {
      return this.handleError(err);
    }
  }

  async sendOtp(payload) {
    try {
      const url = `${this.baseUrl}${Endpoints.sendOTP}`;
      const res = await axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return this.handleResponse(res);
    } catch (err) {
      return this.handleError(err);
    }
  }

  async verifyOtp(payload) {
    try {
      const url = `${this.baseUrl}${Endpoints.verifyOTP}`;
      const res = await axios.post(url, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return this.handleResponse(res);
    } catch (err) {
      return this.handleError(err);
    }
  }
}

const authService = new AuthService();

export default authService;
