import toast from "react-hot-toast";

const defaultOptions = {
  position: "top-center",
};

const useNotification = () => {
  const success = (message, options) => {
    toast.success(message, { ...defaultOptions, ...options });
  };
  const error = (message, options) => {
    toast.error(message, { ...defaultOptions, ...options });
  };
  const promise = (promise, options) => {
    toast.promise(promise, { ...options });
  };
  const custom = (t) => {
    toast(t);
  };

  return {
    success,
    error,
    promise,
    custom,
  };
};

export default useNotification;
