import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container my-4">
      <h1 className="page-heading">Privacy Policy</h1>
      <div className="privacy-policy">
        <p>
          At Grabeasy, we value your privacy and are committed to protecting
          your personal information. By providing us with your personal data
          (such as your phone number, name, and email address), you agree to the
          following terms:
        </p>

        <h3>What Information We Collect</h3>
        <p>
          We collect the following personal information from you when you place
          an order or sign up for our services:
        </p>
        <ul>
          <li>First and Last Name</li>
          <li>Phone Number</li>
          <li>Email Address</li>
          <li>
            Loyalty and Reward Program Information (including purchase history
            and earned points)
          </li>
        </ul>

        <h3>Why We Collect Your Information</h3>
        <p>We collect your personal information to:</p>

        <ul>
          <li>Process and deliver your orders.</li>
          <li>Communicate with you regarding your order status.</li>
          <li>Provide customer support.</li>
          <li>
            <b>Manage our Loyalty and Rewards Program:</b> We collect and track
            information related to your loyalty points and rewards to ensure you
            receive your benefits, such as free burgers or points earned by
            completing tasks. This helps us provide a better, more personalized
            experience for our loyal customers.
          </li>
          <li>
            Send you promotional offers, discounts, and updates (only if you
            have opted in to receive marketing communications).
          </li>
        </ul>

        <h3>Your Privacy Rights</h3>
        <p>Under applicable laws, you have the right to:</p>
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Access the personal information we hold about you.</li>
          <li>Request the deletion or modification of your personal data.</li>
          <li>Opt out of marketing communications at any time.</li>
        </ul>

        <p>
          To exercise any of these rights, please contact us at{" "}
          <a href="mailto:info@grabeasy.com">info@grabeasy.com</a>
        </p>

        <h3>How We Protect Your Information</h3>
        <p>
          Your personal data is securely stored and is only shared with third
          party service providers who help us fulfill your orders, such as
          payment processors or delivery partners. We do not sell your personal
          information to third parties.
        </p>

        <h3>Consent</h3>
        <p>
          By providing your personal information, you consent to the collection,
          storage, and use of your data as described in this policy. If you do
          not agree with these terms, please refrain from providing your
          personal information or making a purchase.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
