import React from "react";

const NoResults = () => {
  return (
    <div className="container">
      <div className="no-results">
        <img src="/images/no-results.svg" alt="" />
        <h2>No Results!</h2>
      </div>
    </div>
  );
};

export default NoResults;
