import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk to fetch restaurants
export const fetchRestaurants = createAsyncThunk('api/fetchRestaurants', async () => {
  const response = await fetch(`${process.env.API_BASE_URL}/get_restaurants`);
  if (!response.ok) {
    throw new Error('Failed to fetch restaurants');
  }
  return response.json(); // Assuming the response is JSON
});

// Async thunk to fetch menus
export const fetchMenus = createAsyncThunk('api/fetchMenus', async () => {
  const response = await fetch(`${process.env.API_BASE_URL}/menus`);
  if (!response.ok) {
    throw new Error('Failed to fetch menus');
  }
  return response.json(); // Assuming the response is JSON
});

// Create a slice for managing API state
const apiSlice = createSlice({
  name: 'api',
  initialState: {
    restaurants: [],
    menus: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle restaurant fetching
    builder
      .addCase(fetchRestaurants.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new fetch
      })
      .addCase(fetchRestaurants.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurants = action.payload; // Set fetched restaurants
      })
      .addCase(fetchRestaurants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Set error message
      })
      // Handle menu fetching
      .addCase(fetchMenus.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new fetch
      })
      .addCase(fetchMenus.fulfilled, (state, action) => {
        state.loading = false;
        state.menus = action.payload; // Set fetched menus
      })
      .addCase(fetchMenus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Set error message
      });
  },
});



// Export the reducer to be used in the store
export default apiSlice.reducer;