import React, { useState } from "react";
import Button from "../../../components/Button";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PRIVACY_POLICY_ROUTE } from "../../../routes";

const LoginForm = ({ handleChangeInput, handleFormSubmit, formGroup }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    await handleFormSubmit()
      .then(() => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleValidate = (e) => {
    const charCode = e.key;

    if (
      !/[0-9]/.test(charCode) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(charCode)
    ) {
      e.preventDefault();
    }
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <div className="auth-info mb-3">
        <h2>What’s your mobile number?</h2>
        <p>We need this to verify and secure your account</p>
      </div>
      <div className="login-input-box mb-3">
        <p>Enter your Phone Number</p>
        <div className="w-100 d-flex align-items-center gap-1 login-input">
          <span className="m-0">+1</span>
          <input
            type="text"
            name="mobile"
            className=""
            placeholder="234567890"
            required
            onKeyDown={handleValidate}
            value={formGroup.mobile}
            onChange={handleChangeInput}
          />
        </div>
        <label className="w-100 agree-box">
          <input type="checkbox" required />
          <span className="text-xs">
            I have read and agree to the{" "}
            <Link className="agree" to={PRIVACY_POLICY_ROUTE}>
              Grabeasy Privacy Policy.
            </Link>
          </span>
        </label>
      </div>
      <div className="my-3 w-75">
        <Button type="submit" minWidth="100%" disabled={isSubmitting}>
          {isSubmitting ? <Spinner size="sm" animation="border" /> : "Login"}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
