import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedLocation: null, // Store restaurant name
  selectedLocationId: '', // Store external ID
  modalVisible: true,
  activeType: 'pickup',
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      console.log("Action ==>",action);
      
      state.selectedLocation = action.payload; // Update selected location
    
    },
    setModalVisible: (state, action) => {
      state.modalVisible = action.payload; // Update modal visibility
    },
    setActiveType: (state, action) => {
      state.activeType = action.payload; // Update active type (pickup or delivery)
    },
  },
});

// Export actions for use in components
export const { setLocation, setModalVisible, setActiveType } = locationSlice.actions;

// Export the reducer to be used in the store
export default locationSlice.reducer;