import React, { useState } from "react";
import { IoMenu } from "react-icons/io5";
import Drawer from "./Drawer";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FAQS_ROUTE, HOME_ROUTE, ORDER_HISTORY_ROUTE } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../reducers/auth";
import Auth from "../features/auth/components/Auth";
import useNotification from "../hooks/useNotification";

const MenuDrawer = ({ show, setShow, handleLogin }) => {
  const storeDispatch = useDispatch();

  const session = useSelector((state) => state.auth);
  const notification = useNotification();

  const handleLogout = () => {
    storeDispatch(authActions.logout());
    notification.success("Logged out successfully!");
    setShow(false);
  };

  return (
    <Drawer
      show={show}
      setShow={setShow}
      headerClassName="account-menu-drawer-header"
      style={{
        width: "300px",
        backgroundColor: "#e57300",
      }}
    >
      <div className="account-menu">
        <Link className="account-menu-item" to={FAQS_ROUTE}>
          FAQs
        </Link>
        {session?.isLoggedIn && (
          <>
            <Link className="account-menu-item" to={ORDER_HISTORY_ROUTE}>
              Order History
            </Link>
            <button className="account-menu-item" onClick={handleLogout}>
              Logout
            </button>
          </>
        )}

        {!session?.isLoggedIn && (
          <button className="account-menu-item" onClick={handleLogin}>
            Login
          </button>
        )}
      </div>
    </Drawer>
  );
};

const AccountMenu = () => {
  const [show, setShow] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    setShow(true);
  };

  const handleLogin = () => {
    navigate(`${pathname}?continue=${pathname}`);
    setShowAuthModal(true);
  };

  return (
    <>
      <button className="account-hamburger" onClick={handleClick}>
        <IoMenu />
      </button>

      <MenuDrawer show={show} setShow={setShow} handleLogin={handleLogin} />

      <Auth showModal={showAuthModal} setShowModal={setShowAuthModal} />
    </>
  );
};

export default AccountMenu;
