import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { X, Gear } from "react-bootstrap-icons";
import { addToCart } from "../reducers/cartReducer"; // Adjust import path as necessary
import { store } from "../store/store";
import useNotification from "../hooks/useNotification";

const AddToCartPopup = ({ show, handleClose, product }) => {
  const [quantity, setQuantity] = useState(1);
  const [modifierState, setModifierState] = useState({}); // State to keep track of selected modifiers
  const [requiredMods, setRequiredMods] = useState(); // this state will keep the track of required mods
  const notification = useNotification();

  // this method will first hand configure all the rquired mods in the background
  const configRequiredModifiers = () => {
    const requiredNames = product.modifierGroupReferences
      .filter((item) => item.requiredMode === "REQUIRED")
      .map((item) => {
        return { name: item.name, min: item.minSelections };
      });
    setRequiredMods([...requiredNames]);
  };

  useEffect(() => {
    // we only want to config new modifiers that why we have wrapped it in an if statement
    if (show) {
      setQuantity(1);
      configRequiredModifiers();
    }
    // this is a cleanup function it will trigger when the modal unmounts
    return () => {
      setModifierState({});
    };
  }, [show]);

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      return newQuantity < 1 ? 1 : newQuantity;
    });
  };

  const handleAddToCart = () => {
    let isValid = true;

    for (let i = 0; i < requiredMods.length; i++) {
      const item = requiredMods[i];
      if (!modifierState[item.name]) {
        isValid = false;
        alert(`Please Select ${item.name}`);
        break;
      }

      const selectedMods = modifierState[item.name];
      const selectedCount = Array.isArray(selectedMods)
        ? selectedMods.length
        : Object.keys(selectedMods).length;

      if (selectedCount < item.min) {
        isValid = false;
        alert(`Please Select at least ${item.min} ${item.name}`);
        break;
      }
    }

    if (!isValid) return;

    const combinedMods = Object.values(modifierState).flatMap((value) =>
      Array.isArray(value) ? value : [value]
    );
    const modifierPrice = combinedMods.reduce(
      (acc, item) => acc + item.price,
      0
    );

    const itemNew = {
      menu_guid: product.menu_guid,
      menu_group_name: product.menu_group_name,
      menu_group_guid: product.menu_group_guid,
      menu_item_name: product.menu_item_name,
      menu_item_guid: product.menu_item_guid,
      quantity: quantity,
      modifiers: combinedMods,
      totalPrice: (product.menu_item_price + modifierPrice) * quantity,
      pimage: product.menu_item_image,
    };
    store.dispatch(addToCart(itemNew));
    notification.success(`Added to cart!`);
    handleClose(); // Close the modal after adding to cart
    // updateViewCart(true);
    // setCartVisible(true);
  };

  const onSelectRadioButton = (mod, selected) => {
    console.log("mod current state ==>", modifierState);

    const { name, guid } = mod;
    // checking if the property exists i.e
    // checking if an element or such property has been added or not
    if (modifierState[name] !== undefined) {
      // since the values exists we are going to change the selected property
      // i.e this means user is trying to select another item for the selection
      let _state = modifierState;
      _state[name] = {
        modifier_group_name: name,
        modifier_group_guid: guid,
        modifier_option_name: selected.name,
        modifier_option_guid: selected.guid,
        price: parseFloat(selected.price),
      };
      console.log("updated State ==>", _state);
      setModifierState({ ..._state });
    } else {
      // this blocks creates the selection in the modifierState variable
      let _state = modifierState;
      _state[name] = {
        modifier_group_name: name,
        modifier_group_guid: guid,
        modifier_option_name: selected.name,
        modifier_option_guid: selected.guid,
        price: parseFloat(selected.price),
      };
      console.log("added State ==>", _state);
      setModifierState({ ...modifierState, ..._state });
    }
  };

  const onSelectCheckbox = (mod, selected) => {
    console.log("mod cust ==>", mod.maxSelections);

    const { name, guid, maxSelections } = mod;
    // checking if the property exists i.e
    // checking if an element or such property has been added or not
    if (modifierState[name] !== undefined) {
      // console.log(modifierState[name].find((item) => item.modifier_option_guid == selected.guid));
      /// here we will check if the value sent to this method already exists in our state variable or not
      if (
        modifierState[name].find(
          (item) => item.modifier_option_guid == selected.guid
        ) !== undefined
      ) {
        // since the values exists we are going to remove it from our state variable
        // i.e this means user is trying to unselect an item for the selection
        let _state = modifierState;
        let _filteredSelection = modifierState[name].filter(
          (item) => item.modifier_option_guid !== selected.guid
        );
        _state[name] = _filteredSelection;
        console.log("_filteredSelection ==>", _filteredSelection);
        setModifierState({ ...modifierState, ..._state });
      } else {
        // as the value dose not exists in our state variable this means user is trying to add another value
        //here we are going to check the limit of selection,
        if (
          maxSelections !== null &&
          modifierState[name].length >= maxSelections
        ) {
          // if the limit is reached dont to any thing
        } else {
          // else we append the new value in out state variable
          let _state = modifierState;
          _state[name] = [
            ..._state[name],
            {
              modifier_group_name: name,
              modifier_group_guid: guid,
              modifier_option_name: selected.name,
              modifier_option_guid: selected.guid,
              price: parseFloat(selected.price),
              multi: true,
            },
          ];
          console.log("added State ==>", _state);
          setModifierState({ ...modifierState, ..._state });
        }
      }
    } else {
      // this blocks creates the selection in the modifierState variables
      let _state = modifierState;
      _state[name] = [
        {
          modifier_group_name: name,
          modifier_group_guid: guid,
          modifier_option_name: selected.name,
          modifier_option_guid: selected.guid,
          price: parseFloat(selected.price),
          multi: true,
        },
      ];
      console.log("added State ==>", _state);
      setModifierState({ ...modifierState, ..._state });
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="add-to-cart-popup custom-modal"
      >
        <Modal.Header
          className="position-relative modalheader"
          style={{ border: "none !important" }}
        >
          <Button
            variant="light"
            className="close-button"
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "5px",
              right: "10px",
              backgroundColor: "#FE9B1987",
              border: "none",
              color: "black",
              borderRadius: "50%",
              fontSize: "20px",
              paddingBottom: "10px",
            }}
          >
            <X />
          </Button>
          {/* <Button
            variant="light"
            className="settings-button"
            style={{
              position: "absolute",
              top: "5px",
              right: "70px",
              backgroundColor: "#FE9B19E5",
              border: "none",
              color: "white",
              borderRadius: "50%",
              fontSize: "20px",
              paddingBottom: "10px",
            }}
          >
            <Gear />
          </Button> */}
        </Modal.Header>
        <Modal.Body style={{ height: "500px" }}>
          <Row style={{ height: "100%", overflow: "auto" }}>
            <Col md={5} lg={5}>
              <img
                src={product.menu_item_image}
                className="product-image"
                alt={product.title}
              />
            </Col>
            <Col md={7} lg={7} style={{ height: "100%", overflow: "auto" }}>
              <h5 className="product-title">{product.menu_item_name}</h5>
              <p className="product-price">
                {product.menu_item_price > 0
                  ? `$${product.menu_item_price.toFixed(2)}`
                  : null}
              </p>{" "}
              {/* Ensure price is safe to call toFixed() */}
              <hr className="divider" />
              <h6 className="description-heading">Description</h6>
              <p className="product-description">
                {product.menu_item_description}
              </p>
              {/* Remaining code remains unchanged */}
              <Container className="modfiercontainer">
                {/* Required Modifiers */}
                {product.modifierGroupReferences.map((group) => (
                  <div
                    key={group.referenceId}
                    className={
                      group.requiredMode === "REQUIRED"
                        ? "modifier-group sauce-container required"
                        : "modifier-group optional"
                    }
                  >
                    <h3>
                      {group.requiredMode === "REQUIRED"
                        ? `${group.name}  (Required)`
                        : group.name}
                    </h3>
                    {group.isMultiSelect
                      ? group.modifierOptionReferences.map((option) => (
                          <div
                            key={option.referenceId}
                            className="modifier-option"
                          >
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                onSelectCheckbox(group, option);
                              }}
                            />
                            {option.name} $
                            {option.price > 0 ? option.price.toFixed(2) : "0"}
                          </div>
                        ))
                      : group.modifierOptionReferences.map((option) => (
                          <div
                            key={option.referenceId}
                            className="modifier-option"
                          >
                            <input
                              type="radio"
                              name={group.name}
                              onChange={(e) =>
                                onSelectRadioButton(group, option)
                              }
                            />
                            {option.name} $
                            {option.price > 0 ? option.price.toFixed(2) : "0"}
                          </div>
                        ))}
                  </div>
                ))}
              </Container>
              <Container className="mt-5">
                <Row>
                  <Col
                    lg={6}
                    className="d-flex align-items-center quantitycolum"
                  >
                    <h6
                      className=""
                      style={{
                        color: "black",
                        fontWeight: "600",
                        paddingRight: "10px",
                      }}
                    >
                      Quantity:
                    </h6>
                    <Button
                      variant="light"
                      className="quantity-button"
                      onClick={() => handleQuantityChange(-1)}
                    >
                      -
                    </Button>
                    <span className="quantity-value">{quantity}</span>
                    <Button
                      variant="light"
                      className="quantity-button"
                      onClick={() => handleQuantityChange(1)}
                    >
                      +
                    </Button>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    <Button
                      className="add-to-cart-button"
                      onClick={handleAddToCart}
                    >
                      Add to Cart
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddToCartPopup;
