import React, { useState } from "react";
import Button from "../../../components/Button";
import { BiSolidEdit } from "react-icons/bi";
import OTPInput from "react-otp-input";
import useNotification from "../../../hooks/useNotification";
import { Spinner } from "react-bootstrap";

const VerifyOtp = ({ handleFormSubmit, mobile, setStep }) => {
  const [otp, setOtp] = useState("");
  const notification = useNotification();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otp || otp.length < 6) {
      return notification.error("Please enter the otp");
    }

    setIsSubmitting(true);

    await handleFormSubmit(otp)
      .then(() => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const editMobile = () => {
    setStep("initialLogin");
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <div className="auth-info mb-1">
        <h2>Verify your mobile number</h2>
        <p>Enter 6-digit code send to your mobile number</p>
      </div>

      <div className="entered-number mb-4">
        <p>+1 {mobile}</p>
        <span onClick={editMobile}>
          <BiSolidEdit />
        </span>
      </div>
      <div className="login-input-box mb-3">
        <OTPInput
          value={otp}
          onChange={setOtp}
          containerStyle={{
            gap: "10px",
          }}
          inputType="number"
          numInputs={6}
          renderInput={(props) => <input {...props} className="otp-input" />}
        />
      </div>
      <div className="my-3 w-75">
        <Button
          type="submit"
          minWidth="100%"
          disabled={otp.length < 6 || isSubmitting}
        >
          {isSubmitting ? <Spinner size="sm" animation="border" /> : "Continue"}
        </Button>
      </div>
    </form>
  );
};

export default VerifyOtp;
