import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { setLocation, setModalVisible } from "../src/actions/locationSlice";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AddToCartPopup from "./components/AddToCartPopup";
import CartPopup from "./components/CartPopup";
import LocationModal from "./components/LocationModal";
import { addToCart, clearCart } from "../src/reducers/cartReducer";
import api from "./services/api";
import { useQuery } from "@tanstack/react-query";
import customerService from "./services/customer-service";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import NoResults from "./components/NoResults";

const Categories = ({ activeTab, setActiveTab }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["product-categories"],
    queryFn: () => customerService.fetchCategories(),
  });

  useEffect(() => {
    if (data?.length > 0) {
      setActiveTab(data[0]?.menu_group_id);
    }
  }, [data]);

  const onChangeCategory = (categoryId) => {
    setActiveTab(categoryId);
  };

  if (isLoading) {
    return <></>;
  }

  if (error) {
    return <></>;
  }

  return (
    <section className="categories-section">
      <ul className="nav nav-tabs" role="tablist">
        {data?.map((group, index) => (
          <li className="nav-item" key={group.menu_group_id}>
            <a
              className={`nav-link ${
                activeTab === group.menu_group_id ? "active" : ""
              }`}
              onClick={() => {
                onChangeCategory(group.menu_group_id);
              }}
              role="tab"
              style={{ cursor: "pointer" }}
            >
              {group.menu_group_name}
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

const Menu = ({ categoryId, setSelectedProduct, setShowPopup, searchQ }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["category-menus", categoryId],
    queryFn: () => customerService.fetchProductsbyId(categoryId),
    refetchOnWindowFocus: false,
  });
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      setMenuItems(data);
    }
  }, [data]);

  const handleFilter = (products, query) => {
    if (products?.length <= 0) return [];

    if (query == "") return products;

    return products.filter((product) =>
      product?.menu_item_name
        ?.trim()
        ?.toLowerCase()
        ?.includes(query?.trim()?.toLowerCase())
    );
  };

  useEffect(() => {
    const isSearch = () => {
      const filteredProds = handleFilter(data, searchQ);
      setMenuItems(filteredProds);
    };

    isSearch();

    return () => {};
  }, [searchQ]);

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col md={3}>
            <div className="productdiv-skeleton">
              <Skeleton height={"150px"} circle />
              <div className="mt-4">
                <Skeleton count={5} />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="productdiv-skeleton">
              <Skeleton height={"150px"} circle />
              <div className="mt-4">
                <Skeleton count={5} />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="productdiv-skeleton">
              <Skeleton height={"150px"} circle />
              <div className="mt-4">
                <Skeleton count={5} />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="productdiv-skeleton">
              <Skeleton height={"150px"} circle />
              <div className="mt-4">
                <Skeleton count={5} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return <></>;
  }

  return (
    <section className="py-5">
      <Container>
        <Row className="justify-content-center">
          <div className="tab-content">
            <div className="tab-pane fade show active" role="tabpanel">
              <Container>
                <Row>
                  {Array.isArray(menuItems) &&
                    menuItems.map((product) => (
                      <Col
                        md={3}
                        key={product.menu_item_id}
                        style={{
                          maxHeight: "470px",
                          marginBottom: "50px",
                        }}
                      >
                        <div
                          className="productdiv"
                          // style={{
                          //   height: "100%",
                          // }}
                        >
                          <img
                            src={product.menu_item_image}
                            alt={product.menu_item_image}
                            className="img-fluid"
                          />
                          <div className="innercontent">
                            <h5>{product.menu_item_name}</h5>
                            <p>{product.menu_item_description}</p>
                          </div>

                          <span>
                            {product.menu_item_price > 0
                              ? `$${product.menu_item_price.toFixed(2)}`
                              : ""}
                          </span>
                          <div className="add-to-cart">
                            <a
                              onClick={() => {
                                setSelectedProduct(product);
                                setShowPopup(true);
                              }}
                            >
                              <FaPlus />
                            </a>
                          </div>
                        </div>
                      </Col>
                    ))}

                  {menuItems?.length <= 0 && <NoResults />}
                </Row>
              </Container>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

const SearchBar = ({ handleSearch }) => {
  const [searchQ, setSearchQ] = useState("");

  const handleChange = (e) => {
    setSearchQ(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // handleSearch(searchQ);
  };

  return (
    <section className="py-3">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <div className="search-container">
              <Form onSubmit={handleSubmit}>
                <Form.Control
                  type="text"
                  placeholder="Search Items"
                  className="searchbar"
                  // value={searchQ}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <button type="submit" className="search-icon">
                  <FaSearch />
                </button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const MenuSection = ({ activeTab }) => {
  const [searchQ, setSearchQ] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleSearch = (query) => {
    setSearchQ(query);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <SearchBar handleSearch={handleSearch} />

      <Menu
        categoryId={activeTab}
        setSelectedProduct={setSelectedProduct}
        setShowPopup={setShowPopup}
        searchQ={searchQ}
      />

      {selectedProduct && (
        <AddToCartPopup
          show={showPopup}
          handleClose={handleClosePopup}
          product={selectedProduct}
        />
      )}
    </>
  );
};

const LandingPage = () => {
  const [showCartPopup, setShowCartPopup] = useState(false); // State for CartPopup

  const [menuGroups, setMenuGroups] = useState([]); // State to store menu groups
  const [activeTab, setActiveTab] = useState(0); // hholds the categiry id
  const [contentData, setContentData] = useState(null); // State to store content data

  const locationModalStatus = useSelector(
    (state) => state.location.modalVisible
  );
  const selectedLocation = useSelector(
    (state) => state.location.selectedLocation
  );
  const cartItems = useSelector((state) => state.cart.items);

  const dispatch = useDispatch();

  const getSubTotal = () => {
    const subTotal = cartItems.reduce((acc, item) => {
      console.log("item in function", item);
      return (acc += item?.totalPrice);
      // * item.quantity
    }, 0);

    return subTotal;
  };

  useEffect(() => {
    if (!selectedLocation) {
      dispatch(setModalVisible(true)); // Show modal if no location is selected
    }
  }, [dispatch, selectedLocation]);

  // Function to handle adding to cart
  const updateQuantity = (product, quantity) => {
    console.log("Product:", product, "Quantity:", quantity);
    dispatch(addToCart({ item: product, quantity: 1 }));
  };

  const handleViewCartClick = () => {
    setShowCartPopup(true);
  };

  const handleCartClear = () => {
    dispatch(clearCart());
    setShowCartPopup(false);
  };

  // useEffect(() => {
  //   if (selectedLocation?.id) {
  //     api.fetchCategories(setMenuGroups);
  //   }
  // }, [selectedLocation]);

  // useEffect(() => {
  //   if (menuGroups.length > 0) {
  //     api.fetchProductsbyId(
  //       menuGroups[activeTab].menu_group_id,
  //       setContentData
  //     );
  //   }
  // }, [menuGroups]);

  return (
    <div>
      {/* Header */}
      <Header handleViewCartClick={handleViewCartClick} />

      {/* Banner Section */}
      <section className="bannersection">
        <img src="/images/banner.jpeg" className="w-100" />
      </section>

      {/* Third Section - Categories */}
      {/* <section className="categories-section">
        <ul className="nav nav-tabs" role="tablist">
          {menuGroups.map((group, index) => (
            <li className="nav-item" key={group.menu_group_id}>
              <a
                className={`nav-link ${activeTab === index ? "active" : ""}`}
                onClick={() => {
                  onChangeCategory(index); // Fetch content when the tab is clicked
                }}
                role="tab"
                style={{ cursor: "pointer" }}
              >
                {group.menu_group_name}
              </a>
            </li>
          ))}
        </ul>
      </section> */}
      {selectedLocation?.id && (
        <Categories activeTab={activeTab} setActiveTab={setActiveTab} />
      )}

      {/* Fourth Section - Search Bar */}

      <MenuSection activeTab={activeTab} />

      {/* <section className="py-5">
        <Container>
          <Row className="justify-content-center">
            <div className="tab-content">
              <div className="tab-pane fade show active" role="tabpanel">
                <Container>
                  <Row>
                    {Array.isArray(contentData) &&
                      contentData.map((product) => (
                        <Col md={3} key={product.menu_item_id}>
                          <div className="productdiv">
                            <img
                              src={product.menu_item_image}
                              alt={product.menu_item_image}
                              className="img-fluid"
                            />
                            <div className="innercontent">
                              <h5>{product.menu_item_name}</h5>
                              <p>{product.menu_item_description}</p>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                                {product.menu_item_price > 0
                                  ? `$${product.menu_item_price.toFixed(2)}`
                                  : ""}
                              </span>
                              <a
                                onClick={() => {
                                  setSelectedProduct(product);
                                  setShowPopup(true);
                                }}
                              >
                                <FaPlus />
                              </a>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </Container>
              </div>
            </div>
          </Row>
        </Container>
      </section> */}

      {/* About Us - Section */}
      <section style={{ background: "#F9F9F9", padding: "40px" }}>
        <Container className="container">
          <Row>
            <Col lg={12}>
              <h1 className="text-center" style={{ color: "#FC8F1A" }}>
                About GrabEasy
              </h1>
              <p
                className="text-center"
                style={{
                  color: "#000000",
                  lineHeight: "35px",
                  fontSize: "18px",
                }}
              >
                Experience the Crave-Worthy. Welcome to GrabEasy, America's
                go-to destination for satisfying fast food. Our menu is packed
                with mouthwatering options, from juicy burgers and crispy
                sandwiches to sizzling barbecues and golden fries. We source the
                finest ingredients to ensure every bite is bursting with flavor.
                Our diverse menu offers something for everyone, from classic
                burgers to innovative creations. Enjoy quick and efficient
                service, whether you dine-in or take-out. Indulge in our juicy
                burgers, made with fresh juicy beef and topped with your
                favorite fixings. Discover the crispy sandwiches, featuring
                fresh bread and flavorful fillings. Experience the smoky
                goodness of our grilled meats and savory sauces. Our crispy
                fries are the perfect side to any meal, and our refreshing
                beverages will quench your thirst.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* AddToCartPopup */}

      {/* Footer */}
      <Footer />

      {/* Location Modal */}
      {/* {locationModalStatus && ( */}
      <LocationModal
        setSelectedLocation={(location) => {
          dispatch(setLocation(location));
          dispatch(setModalVisible(false)); // Close modal after setting location
        }}
        onClose={() => dispatch(setModalVisible(false))}
      />
      {/* )} */}

      {/* CartPopup */}
      <CartPopup
        onClose={() => setShowCartPopup(false)}
        onCartClear={handleCartClear}
        show={showCartPopup}
      />

      {/* Render View Cart Button Conditionally */}
      {cartItems.length > 0 && (
        <button onClick={handleViewCartClick} className="viewcartbtn">
          <span
            style={{
              paddingRight: "0px",
              background: "#fff",
              width: "30px",
              height: "30px",
              color: "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100%",
            }}
          >
            {cartItems.length}
          </span>
          <span style={{ fontSize: "16px" }}>View Cart</span>
          <span style={{ fontSize: "14px" }}>${getSubTotal().toFixed(2)}</span>
        </button>
      )}
    </div>
  );
};

export default LandingPage;
