import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import { Form } from "react-bootstrap";

const PaymentForm = () => {
  return (
    <Form.Group controlId="paymentInfo" className="mt-4">
      <Form.Label>Payment Information</Form.Label>
      <div className="payment-form">
        <div className="card-number">
          <label htmlFor="cardNumber">Card Number</label>
          <div className="stripe-card-element">
            <CardNumberElement id="cardNumber" />
          </div>
        </div>

        <div className="card-expiry">
          <label>Expiration Date</label>
          <div className="stripe-card-element">
            <CardExpiryElement id="cardExpiry" />
          </div>
        </div>

        <div className="card-cvc">
          <label>Security Code</label>
          <div className="stripe-card-element">
            <CardCvcElement id="cardCVC" />
          </div>
        </div>

        {/* <div className="card-country">
          <label>Country</label>
          <Form.Control as="select">
            <option value="" disabled>
              Select Country
            </option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
            <option value="GB">United Kingdom</option>
          </Form.Control>
        </div> */}

        {/* <div className="card-zip-code">
          <label htmlFor="zipcode">Zip Code</label>
          <Form.Control
            type="text"
            placeholder="12345"
            className="text-input"
          />
        </div> */}
      </div>
    </Form.Group>
  );
};

export default PaymentForm;
