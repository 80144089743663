import React from "react";
import LogoHeader from "../../components/LogoHeader";
import Footer from "../../components/Footer";
import OrderHistory from "../../features/customer/order/components/OrderHistory";

const OrderHistoryPage = () => {
  return (
    <>
      <LogoHeader showBackBtn />
      <OrderHistory />
      <Footer />
    </>
  );
};

export default OrderHistoryPage;
