import Cookies from "js-cookie";

class CookieService {
  set(name, value, options = {}) {
    Cookies.set(name, value, options);
  }
  get(name) {
    return Cookies.get(name);
  }
  remove(name) {
    Cookies.remove(name);
  }
  getAll() {
    return Cookies.get();
  }
}

const cookieService = new CookieService();

export default cookieService;
