import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landingpage from "./LandingPage";
import CheckoutPage from "./pages/CheckoutPage";
import ThankYouPage from "./pages/ThankYouPage";
import StripeProvider from "./providers/StripeProvider";
import OrderHistoryPage from "./pages/customer/OrderHistoryPage";
import OrderDetailPage from "./pages/customer/OrderDetailPage";
import PrivacyPolicyPage from "./pages/site/PrivacyPolicyPage";
import FaqsPage from "./pages/site/FaqsPage";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Toaster } from "react-hot-toast";

import {
  FAQS_ROUTE,
  ORDER_DETAIL_ROUTE,
  ORDER_HISTORY_ROUTE,
  PRIVACY_POLICY_ROUTE,
  THANK_YOU_ROUTE,
} from "./routes";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <StripeProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Landingpage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path={`${THANK_YOU_ROUTE}/:id`} element={<ThankYouPage />} />
            <Route path={ORDER_HISTORY_ROUTE} element={<OrderHistoryPage />} />
            <Route
              path={`${ORDER_DETAIL_ROUTE}/:id`}
              element={<OrderDetailPage />}
            />
            <Route
              path={PRIVACY_POLICY_ROUTE}
              element={<PrivacyPolicyPage />}
            />
            <Route path={FAQS_ROUTE} element={<FaqsPage />} />
            {/* Other routes */}
          </Routes>
        </Router>
      </StripeProvider>
      <Toaster position="bottom-left" reverseOrder={false} />
    </QueryClientProvider>
  );
  // return (
  //   <div className='App'>
  //   <header className='App-header'>
  //   <div class='alert alert-primary' role='alert'>
  //   <p style={{ display: "none" }} className='d-block'>
  //   Bootstrap is now successfully installed 😃
  //   </p>
  //   <p className='d-none'>
  //   Bootstrap is not installed if you can see this 😢
  //   </p>
  //   </div>
  //   </header>
  //   </div>
  //   );
}

export default App;
