import React from "react";
import LogoHeader from "../../components/LogoHeader";
import Footer from "../../components/Footer";
import OrderDetail from "../../features/customer/order/components/OrderDetail";

const OrderDetailPage = () => {
  return (
    <>
      <LogoHeader showBackBtn />
      <OrderDetail />
      <Footer />
    </>
  );
};

export default OrderDetailPage;
