import React, { useState } from "react";
import { useForm } from "react-hook-form";
import authService from "../../../services/auth-service";
import useNotification from "../../../hooks/useNotification";
import { useDispatch } from "react-redux";
import { authActions } from "../../../reducers/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

const useAuthForm = ({ setShowModal }) => {
  const [formGroup, setFormGroup] = useState({
    mobile: null,
    firstname: "",
    lastname: "",
    email: "",
  });

  //   steps can be initialLogin || verifyOtp || register
  const [step, setStep] = useState("initialLogin");

  const searchParams = useSearchParams();
  const navigate = useNavigate();
  const notification = useNotification();
  const storeDispatch = useDispatch();

  const closeAuthModal = () => {
    setShowModal(false);
  };

  const handleChangeInput = (e) => {
    setFormGroup({ ...formGroup, [e.target.name]: e.target.value });
  };

  const handleAuthenticate = async (init) => {
    const formData = new FormData();

    if (init) {
      formData.append("mobile", `+1${formGroup["mobile"]}`);
    } else {
      Object.keys(formGroup).forEach((key) => {
        if (key === "mobile") {
          formData.append("mobile", `+1${formGroup["mobile"]}`);
        } else {
          formData.append(key, formGroup[key]);
        }
      });
    }

    const res = await authService.authenticate(formData);

    if (res.success) {
      notification.success(res.message);

      const session = {
        token: res?.data?.token,
        user: res?.data,
      };

      storeDispatch(authActions.setAuth(session));
      setShowModal(false);
      setStep("initialLogin");

      const continuePath = searchParams[0].get("continue");

      if (continuePath) {
        navigate(continuePath);
      } else {
        navigate("/");
      }
    } else {
      if (res.code === 4002) {
        setStep("register");
      } else {
        notification.error(res.error);
      }
    }
  };

  const sendOTP = async () => {
    const formData = new FormData();
    formData.append("mobile", `+1${formGroup["mobile"]}`);

    const res = await authService.sendOtp(formData);

    if (res.success) {
      setStep("verifyOtp");
    } else {
      notification.error("Please enter correct phone number!");
    }
  };

  const verifyOTP = async (otp) => {
    const formData = new FormData();

    formData.append("mobile", `+1${formGroup["mobile"]}`);
    formData.append("otp", otp);

    const res = await authService.verifyOtp(formData);

    if (res.success) {
      await handleAuthenticate(true);
    } else {
      notification.error(res.message ?? "Something went wrong!");
    }
  };

  return {
    handleChangeInput,
    handleAuthenticate,
    verifyOTP,
    step,
    setStep,
    closeAuthModal,
    sendOTP,
    formGroup,
  };
};

export default useAuthForm;
