import axios from "axios";
import Endpoints from "./Endpoints";
import BaseService from "./base-service";
import { store } from "../store/store";
import jwtService from "./local/JwtService";

class CustomerService extends BaseService {
  getOrderHistory() {
    const token = jwtService.getToken();
    return axios
      .get(`${this.baseUrl}${Endpoints.orderHistory}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Toast-Restaurant-ID":
            store.getState().location?.selectedLocation?.restaurant_external_id,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return [];
      });
  }

  getOrderDetail(id) {
    const token = jwtService.getToken();

    return axios
      .get(`${this.baseUrl}${Endpoints.OrderDetails}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Toast-Restaurant-ID":
            store.getState().location?.selectedLocation?.restaurant_external_id,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return {};
      });
  }

  fetchCategories() {
    return axios
      .get(`${this.baseUrl}${Endpoints.categories}`, {
        headers: {
          "Toast-Restaurant-ID":
            store.getState().location.selectedLocation.restaurant_external_id, // Pass the location ID in the header
        },
      })
      .then((response) => {
        return response.data.menugroups;
      })
      .catch((err) => {
        return [];
      });
  }

  fetchProductsbyId(categoryId) {
    return axios
      .get(`${this.baseUrl}${Endpoints.productsById}${categoryId}`, {
        headers: {
          "Toast-Restaurant-ID":
            store.getState().location.selectedLocation.restaurant_external_id, // Pass the location ID in the header
        },
      })
      .then((response) => {
        return response.data?.menuitems ?? [];
      })
      .catch((err) => {
        return [];
      });
  }
}

const customerService = new CustomerService();

export default customerService;
