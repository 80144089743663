import React, { useState } from "react";
import Button from "../../../components/Button";
import { Spinner } from "react-bootstrap";

const RegisterForm = ({ handleFormSubmit, handleChangeInput, mobile }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    await handleFormSubmit()
      .then(() => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <div className="auth-info mb-3">
        <h2>Welcome to GrabEasy</h2>
        <p>Please enter your details to continue</p>
      </div>
      <div className="register-form">
        <div className="row">
          <div className="col-md-6">
            <div className="register-input-box mb-3">
              <label>First Name</label>
              <input
                type="text"
                className="register-input"
                placeholder="Enter your First name"
                name="firstname"
                required
                onChange={handleChangeInput}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="register-input-box mb-3">
              <label>Last Name</label>
              <input
                type="text"
                className="register-input"
                placeholder="Enter your Last name"
                name="lastname"
                required
                onChange={handleChangeInput}
              />
            </div>
          </div>
        </div>

        <div className="register-input-box mb-3">
          <label>Email</label>
          <input
            type="email"
            className="register-input"
            placeholder="Enter your Email"
            name="email"
            required
            onChange={handleChangeInput}
          />
        </div>

        <div className="register-input-box mb-3">
          <label>Mobile Number</label>
          <p className="register-input">{mobile}</p>
        </div>
      </div>
      <div className="my-3 w-75">
        <Button type="submit" minWidth="100%" disabled={isSubmitting}>
          {isSubmitting ? <Spinner size="sm" animation="border" /> : "Continue"}
        </Button>
      </div>
    </form>
  );
};

export default RegisterForm;
