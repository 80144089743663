import React from "react";
import { Link } from "react-router-dom";

const BtnLink = ({
  disabled = false,
  children,
  href,
  minWidth = "100px",
  style,
}) => {
  return (
    <Link
      className="button"
      style={{
        opacity: disabled ? "0.7" : 1,
        pointerEvents: disabled ? "none" : "auto",
        minWidth,
        textDecoration: "none",
        ...style,
      }}
      to={href}
    >
      {children}
    </Link>
  );
};

export default BtnLink;
