import React from "react";
import LogoHeader from "../../components/LogoHeader";
import Footer from "../../components/Footer";
import PrivacyPolicy from "../../components/PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <>
      <LogoHeader showBackBtn />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
