import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";

import { useNavigate } from "react-router-dom";

const BackBtn = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={goBack} className="back-btn">
      <MdKeyboardBackspace
        style={{
          fontSize: "24px",
        }}
      />
      <span>Go Back</span>
    </button>
  );
};

export default BackBtn;
