import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import customerService from "../../../../services/customer-service";
import PageSpinner from "../../../../components/PageSpinner";
import NoResults from "../../../../components/NoResults";
import { ORDER_DETAIL_ROUTE } from "../../../../routes";

const Order = ({ id, no = "", date = "", time = "", amount = "" }) => {
  return (
    <Link className="order-card" to={`${ORDER_DETAIL_ROUTE}/${id}`}>
      <div className="order-detail">
        <h3 className="order-no">Order #{no}</h3>
        <p className="order-datetime">
          {date ? format(date, "EEE, d MMM yyyy") : ""}
        </p>
        <p className="order-datetime">{time ? format(time, "hh:mm a") : ""}</p>
      </div>
      <div className="order-amount">
        <p>${amount}</p>
      </div>
    </Link>
  );
};

const MyOrders = () => {
  const { data, error, isFetching } = useQuery({
    queryKey: ["order-history"],
    queryFn: () => customerService.getOrderHistory(),
    refetchOnWindowFocus: false,
  });

  if (isFetching) {
    return <PageSpinner />;
  }

  if (error) {
    return <></>;
  }

  if (data?.length <= 0) {
    return <NoResults />;
  }

  return (
    <div className="order-history-container">
      {data?.map((order) => (
        <Order
          key={order.id}
          id={order.id}
          no={order.displayNumber}
          date={order.order_date}
          amount={order.totalAmount}
          time={order.order_date}
        />
      ))}
    </div>
  );
};

const OrderHistory = () => {
  return (
    <div
      className="container"
      style={{
        minHeight: "70vh",
      }}
    >
      <h1 className="page-heading">My Orders</h1>
      <MyOrders />
    </div>
  );
};

export default OrderHistory;
