import React from "react";

const Button = ({
  disabled = false,
  children,
  type = "button",
  handleClick,
  minWidth = "100px",
}) => {
  return (
    <button
      className="button"
      disabled={disabled}
      style={{
        opacity: disabled ? "0.7" : 1,
        minWidth,
      }}
      type={type}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
