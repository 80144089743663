import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import AppConfig from "../config";

const stripePromise = loadStripe(AppConfig.stripePublishableKey);

// use this options if using payment element in fomr
// const options = {
//   amount: 1000,
//   currency: "usd",
//   mode: "payment",
//   appearance: {
//     variables: {
//       colorBackground: "#f7f7f7",
//       borderRadius: "none",
//     },
//     rules: {
//       ".AccordionItem": {
//         border: "none",
//         boxShadow: "none",
//       },
//     },
//   },
//   paymentMethodTypes: ["card"],
// };

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
