import AppConfig from "../../config";
import cookieService from "./CookieService";

class JWTService {
  getToken() {
    const session = cookieService.get(AppConfig.userSession);

    if (session) {
      return JSON.parse(session).token;
    }
  }
  setAuthSession(session) {
    cookieService.set(AppConfig.userSession, JSON.stringify(session), {
      expires: 1,
    });
  }
  updateAuthSession(newSession) {
    const oldSession = this.useSession();
    const updatedSession = { ...oldSession, ...newSession };
    cookieService.set(AppConfig.userSession, JSON.stringify(updatedSession), {
      expires: 1,
    });
  }
  useSession() {
    const session = cookieService.get(AppConfig.userSession);
    if (session) {
      return JSON.parse(session);
    }
  }
  clearSession() {
    cookieService.remove(AppConfig.userSession);
  }
}

const jwtService = new JWTService();

export default jwtService;
