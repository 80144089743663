import React from "react";
import { Form } from "react-bootstrap";

const PersonalInfo = ({ authSession }) => {
  const { user } = authSession || {};

  return (
    <>
      <Form.Group controlId="formNearestLandmarkEmail" className="mt-4">
        <div className="d-lg-flex justify-content-between">
          <div className="halfwidth" style={{ marginRight: "20px" }}>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              readOnly
              value={user?.first_name}
              type="text"
              placeholder="First Name"
              className="text-input"
            />
          </div>
          <div className="halfwidth">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              readOnly
              type="text"
              value={user?.last_name}
              placeholder="Last Name"
              className="text-input"
            />
          </div>
        </div>
      </Form.Group>
      <Form.Group controlId="formDeliveryAddress" className="mt-4">
        <Form.Label>Mobile Number</Form.Label>
        <Form.Control
          readOnly
          type="text"
          value={user?.mobile}
          placeholder="Mobile Number"
          className="text-input"
        />
      </Form.Group>
      <Form.Group controlId="formDeliveryAddress" className="mt-4">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          readOnly
          type="email"
          value={user?.email}
          placeholder="Email Address"
          className="text-input"
        />
      </Form.Group>
    </>
  );
};

export default PersonalInfo;
