// services/api.js
import axios from "axios";
import Endpoints from "./Endpoints";
import { store } from "../store/store";

const BaseURL = "https://grabeasy.app/api/";

export default {
  // Function to fetch restaurants
  fetchRestaurants: async (setRestaurants) => {
    axios
      .get(`${BaseURL}${Endpoints.outlets}`)
      .then((response) => {
        setRestaurants(response.data.restaurants);
      })
      .catch((err) => {
        console.log("locatios error", err);
      });
  },
  fetchCategories: async (setCategories) => {
    axios
      .get(`${BaseURL}${Endpoints.categories}`, {
        headers: {
          "Toast-Restaurant-ID":
            store.getState().location.selectedLocation.restaurant_external_id, // Pass the location ID in the header
        },
      })
      .then((response) => {
        console.log("fetched categories ==>", response.data);
        setCategories(response.data.menugroups);
      })
      .catch((err) => console.log("error fetching categories", err));
  },

  fetchProductsbyId: async (id, setProducts) => {
    axios
      .get(`${BaseURL}${Endpoints.productsById}${id}`, {
        headers: {
          "Toast-Restaurant-ID":
            store.getState().location.selectedLocation.restaurant_external_id, // Pass the location ID in the header
        },
      })
      .then((response) => {
        setProducts(response.data.menuitems);
      })
      .catch((err) => {
        console.log("error in getting products =>", err);
      });
  },
  getPickUpOptions: async (setOptions) => {
    return axios
      .get(`${BaseURL}${Endpoints.pickupOptions}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "text/json",
          "Toast-Restaurant-ID":
            store.getState().location?.selectedLocation?.restaurant_external_id,
        },
      })
      .then((response) => {
        console.log("response.data.data) ==>", response.data.data);
        return response.data.data;
      })
      .catch((err) => {
        return [];
      });
  },
};
