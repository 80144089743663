import React, { useState } from "react";
import AuthModal from "./AuthModal";
import LoginForm from "./LoginForm";
import VerifyOtp from "./VerifyOtp";
import RegisterForm from "./RegisterForm";
import useAuthForm from "../hooks/useAuthForm";

const Auth = ({ showModal, setShowModal }) => {
  const {
    closeAuthModal,
    step,
    handleAuthenticate,
    handleChangeInput,
    verifyOTP,
    sendOTP,
    formGroup,
    setStep,
  } = useAuthForm({ setShowModal });

  return (
    <AuthModal show={showModal} handleClose={closeAuthModal}>
      {step === "initialLogin" && (
        <LoginForm
          formGroup={formGroup}
          handleChangeInput={handleChangeInput}
          handleFormSubmit={sendOTP}
        />
      )}
      {step === "verifyOtp" && (
        <VerifyOtp
          handleFormSubmit={verifyOTP}
          mobile={formGroup.mobile}
          setStep={setStep}
        />
      )}
      {step === "register" && (
        <RegisterForm
          mobile={formGroup.mobile}
          handleChangeInput={handleChangeInput}
          handleFormSubmit={handleAuthenticate}
        />
      )}
    </AuthModal>
  );
};

export default Auth;
