import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../services/api";
import orderService from "../../../services/order-service";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import useNotification from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../../reducers/cartReducer";
import { THANK_YOU_ROUTE } from "../../../routes";

const useCheckout = () => {
  const authSession = useSelector((state) => state.auth);
  const cartItems = useSelector((state) => state.cart.items);
  const notification = useNotification();
  const storeDispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [pickupOptions, setPickupOptions] = useState([]);
  const [productsTotal, setProductTotal] = useState(0); // amount of only products
  const [voucher, setVoucher] = useState("");

  const [formGroup, setFormGroup] = useState({
    pickup_drivethru: null,
    products: cartItems,
    preparationtime: 0,
    add_loyalty: 0,
    totalAmount: 0,
    payment_method_type: "card",
    discount_amount: 0,
    discount_item_guid: null,
  });

  const getProductsTotal = () => {
    const subTotal = cartItems.reduce((acc, item) => {
      console.log("item in function", item);
      return (acc += item?.totalPrice);
      // * item.quantity
    }, 0);

    return subTotal?.toFixed(2);
  };

  useEffect(() => {
    const isMount = () => {
      orderService.getPickUpOptions().then((options) => {
        setPickupOptions(options);
      });

      const total = getProductsTotal();
      setProductTotal(total);

      setFormGroup({ ...formGroup, totalAmount: total });
    };

    isMount();
  }, []);

  const applyVoucher = (e) => {
    setVoucher(e.target.value);
  };

  const selectPickupOption = async (e) => {
    const guid = e.target.value;
    const updatedFormGroup = { ...formGroup };

    updatedFormGroup.pickup_drivethru = guid;

    const payload = {
      products: formGroup.products,
      preparationtime: 0,
      pickup_drivethru: guid,
      discount_amount: 0,
      discount_item_guid: null,
    };

    await orderService.checkOrderPrices(payload).then((res) => {
      if (res.success) {
        updatedFormGroup.totalAmount = res.totalamount;
      }
    });

    setFormGroup({ ...updatedFormGroup });
  };

  const handleOrder = async () => {
    setIsLoading(true);

    const cardElement = elements.getElement(CardNumberElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: `${authSession?.user?.first_name} ${authSession?.user?.last_name}`,
      },
    });

    if (error) {
      notification.error(error.message);
      setIsLoading(false);
      return;
    }

    const userAgent = navigator.userAgent;

    const payload = {
      user_agent: userAgent,
      payment_method_id: paymentMethod.id,
      ...formGroup,
    };

    await orderService.createOrder(payload).then((res) => {
      if (res.success) {
        notification.success(res.message);
        storeDispatch(clearCart());

        navigate(`${THANK_YOU_ROUTE}/${res?.data?.order_id}`);
      } else {
        notification.error(res.message ?? "Something went wrong!");
      }
    });

    setIsLoading(false);
  };

  return {
    authSession,
    productsTotal,
    formGroup,
    pickupOptions,
    selectPickupOption,
    voucher,
    applyVoucher,
    handleOrder,
    isLoading,
    setIsLoading,
  };
};

export default useCheckout;
