
export default {
    login: 'authentication',
    sendOTP: "send-otp",
    verifyOTP: "check-otp",
    categories: "categories",
    productsById: "products?menu_group_id=",
    outlets: "get_restaurants",
    saveCard: 'user/save-card',
    getCards: 'user/get-cards',
    tax: "price",
    pickupOptions: "pickup-drivethru",
    placeOrder: "order",
    orderHistory: "order-history",
    OrderDetails: "order-detail/",
    sendGiftcard: 'send-gift-card',
    getLoyaltyPoints: 'loyalty/points?id=0',
    updateProfile: "user/update",
    getGiftCards: 'my-gift-card',
    LoyaltyPrograms: 'loyalty/program',
    addPoints: 'loyalty/points?id=',
    cartItem: "item",
    userDetails: "user/details",
    defaultCard: "user/change-default-card/",
    deleteCard: "user/delete-card/"
}

