import React from "react";
import { Spinner } from "react-bootstrap";

const PageSpinner = ({ minHeight = "400px" }) => {
  return (
    <div
      className="container"
      style={{
        margin: "50px auto",
        minHeight,
      }}
    >
      <div className="w-100 d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    </div>
  );
};

export default PageSpinner;
