import React, { useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FaTimes, FaArrowRight } from "react-icons/fa";
import { FaMinus, FaPlus, FaTrashCan } from "react-icons/fa6";

import { useSelector } from "react-redux";
import {
  clearCart,
  decreaseQuantity,
  increaseQuantity,
} from "../reducers/cartReducer"; // Ensure the correct reducer file path
import { Link, useNavigate, useLocation } from "react-router-dom";
import { store } from "../store/store";
import Auth from "../features/auth/components/Auth";
import useNotification from "../hooks/useNotification";
import { MdAdd, MdDelete } from "react-icons/md";

const EmptyCartMsg = () => {
  return (
    <div className="empty-cart">
      <img src="/images/empty-cart.png" alt="empty-cart" />
      <p className="msg m-0">Your cart is empty</p>
      <p className="m-0">Add items to get started</p>
    </div>
  );
};

const CartPopup = ({ show, onClose, onCartClear }) => {
  const [showAuthModal, setShowAuthModal] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const notification = useNotification();

  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

  const cartItems = useSelector((state) => state.cart.items); // Access cart items

  // Handles all the sumission of the cart items price
  const getSubTotal = () => {
    const subTotal = cartItems.reduce((acc, item) => {
      console.log("item in function", item);
      return (acc += item?.totalPrice);
      // * item.quantity
    }, 0);

    return subTotal;
  };

  // Handle clearing all items from the cart
  const handleClearCart = () => {
    // store.dispatch(clearCart());

    if (cartItems?.length <= 0) {
      notification.success("Your cart is empty!");
      return;
    }

    onCartClear();
    notification.success("Cart has been cleared!");
  };

  const handleIncreaseQuantity = (index) => {
    store.dispatch(increaseQuantity(index)); // Increment quantity
  };

  const handleDecreaseQuantity = (index) => {
    store.dispatch(decreaseQuantity(index));
  };

  const handleClickCheckout = () => {
    if (isAuthenticated) {
      navigate("/checkout");
    } else {
      navigate(`${pathname}?continue=/checkout`);
      setShowAuthModal(true);
    }
  };

  // Check if there are items in the cart
  const hasItemsInCart = cartItems.length > 0;

  return (
    <>
      {/* Overlay */}
      {show && <div className="overlay" onClick={onClose} />}

      {/* Cart Popup */}
      <div className={`cart-popup ${show ? "show" : "hide"}`}>
        {/* Header */}
        <Row
          className="align-items-center mb-1"
          style={{
            paddingLeft: "10px",
          }}
        >
          <Col xs={5}>
            <h5>Your Cart</h5>
          </Col>
          <Col xs={5} className="text-end">
            <Button
              variant="link"
              className="me-3 clearcarticon"
              onClick={handleClearCart}
            >
              Clear Cart
            </Button>
          </Col>
          <Col xs={2} className="text-end">
            <FaTimes
              className="close-icon"
              onClick={onClose}
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Row>

        {/* Cart Items */}
        <Container>
          {hasItemsInCart ? (
            cartItems.map((item, index) => {
              return (
                <Row
                  key={item.menu_item_guid}
                  className="align-items-center mb-5 mt-5"
                >
                  <Col xs={9}>
                    <div className="media">
                      <img className="mr-3" src={item?.pimage} />
                      <div className="media-body">
                        <p className="m-0 p-title">{item?.menu_item_name}</p>

                        <p className="m-0 p-amount">
                          {" "}
                          ${item?.totalPrice.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col xs={3} className="p-0">
                    <div className="qty-box">
                      <button onClick={() => handleDecreaseQuantity(index)}>
                        {item?.quantity > 1 ? <FaMinus /> : <FaTrashCan />}
                      </button>
                      <span>{item?.quantity}</span>
                      <button onClick={() => handleIncreaseQuantity(index)}>
                        <FaPlus />
                      </button>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <EmptyCartMsg />
          )}

          <hr />

          {/* Total Calculations */}
          {/* <Row className="mb-2">
                <Col>
                  <h6>Total</h6>
                </Col>
                <Col className="text-end">
                  <span>${totalPrice.toFixed(2)}</span>
                </Col>
              </Row> */}
          {/* <Row className="mb-2">
                <Col>
                  <h6>Tax 15%</h6>
                </Col>
                <Col className="text-end">
                  <span>${tax.toFixed(2)}</span>
                </Col>
              </Row> */}

          <Row className="mb-3">
            <Col>
              <h6 style={{ color: "black", fontWeight: "bold" }}>Sub Total</h6>
            </Col>
            <Col className="text-end" style={{ color: "black" }}>
              <strong>${getSubTotal().toFixed(2)}</strong>
            </Col>
          </Row>

          {/* Proceed to Checkout Button */}
          {hasItemsInCart ? (
            <Button
              className="w-100 proccedtocheckoutbtn"
              onClick={handleClickCheckout}
            >
              Proceed to Checkout
              <FaArrowRight className="ms-2 btnicon" />
            </Button>
          ) : (
            <Button className="w-100 proccedtocheckoutbtn" disabled>
              Proceed to Checkout
              <FaArrowRight className="ms-2 btnicon" />
            </Button>
          )}
        </Container>
      </div>
      <Auth showModal={showAuthModal} setShowModal={setShowAuthModal} />
    </>
  );
};

export default CartPopup;
