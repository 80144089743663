import AppConfig from "../config";
import jwtService from "./local/JwtService";

class BaseService {
  constructor() {
    this.baseUrl = AppConfig.baseUrl;
  }

  handleResponse(res) {
    if (res?.data?.success) {
      return res?.data;
    } else {
      return res?.response?.data;
    }
  }

  handleError(err) {
    return err?.response?.data;
  }
}

export default BaseService;
