// src/reducers/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
  },
  reducers: {
    addToCart(state, action) {
      state.items.push(action.payload);
    },

    clearCart(state) {
      state.items = []
    },

    decreaseQuantity(state, action) {
      var product = state.items[action.payload];
      // product.totalPrice -= (product.totalPrice / product.quantity)
      if (product.quantity > 1) {
        product.totalPrice -= (product.totalPrice / product.quantity)
        product.quantity -= 1
        state.items[action.payload] = product;
      }
      else {
        state.items.splice(action.payload, 1);
      }


    },
    increaseQuantity(state, action) {
      var product = state.items[action.payload];
      product.totalPrice += (product.totalPrice / product.quantity)
      product.quantity += 1
      state.items[action.payload] = product;

    }

  },
});

export const { addToCart, removeFromCart, clearCart, decreaseQuantity, increaseQuantity } = cartSlice.actions;
export default cartSlice.reducer;